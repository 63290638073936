console.log('App initialized!', new Date().toISOString());

function init() {
  console.log('init run');
  const title = document.createElement('h1');
  title.innerHTML = `Redirect App initialized ${new Date().toISOString()}`;

  document.body.appendChild(title);

  const redirect = localStorage.getItem('__FEATURE_PATH__');
  if (redirect && location.hash) {
    localStorage.clear();
    location.href = `${location.protocol}//${location.host}/${redirect}/${location.hash}`;
  }
}

init();
